import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logotwo from '../cowhite.png';
import Logo from '../coblack.png';

const MainPage = () => {
  const [stores, setStores] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // axios.get('http://192.168.29.80:5001/fetch-stores')
    axios.get('https://cosmyk.uc.r.appspot.com/fetch-stores')
      .then(response => {
        setStores(response.data.stores);
      })
      .catch(error => {
        console.error("There was an error fetching the stores!", error);
      });
  }, []);

  const filteredStores = stores.filter(store =>
    store.store_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleStoreClick = (storeName) => {
    navigate(`/${storeName}`);
  };

  // Styles for the components
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    logo: {
      width: '200px', // Adjust size as needed
      marginTop: '-60px',
    },
    searchBarContainer: {
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    searchBar: {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    content: {
      textAlign: 'left',
      padding: '20px',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '20px', // Increased gap between store boxes
      marginTop: '20px',
    },
    imageWrapper: {
      width: '200px', // Box size remains increased
      height: '200px', // Box height remains increased
      overflow: 'hidden',
      borderRadius: '8px',
      position: 'relative',
      cursor: 'pointer',
      transition: 'transform 0.2s, box-shadow 0.2s, border-color 0.2s', // Smooth transition for scaling, shadow, and border
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Default shadow
      border: '2px solid #ccc', // Default border color
    },
    imageStyle: {
      width: '100%',
      height: '100%',
      objectFit: 'contain', // Set back to contain for full image visibility
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    storeNameContainer: {
      display: 'flex',
      justifyContent: 'center', // Center the store name
      marginTop: '5px',
    },
    storeName: {
      fontSize: '16px',
      maxWidth: '150px', // Set a maximum width for the store name
      whiteSpace: 'nowrap', // Prevent wrapping to the next line
      overflow: 'hidden', // Hide overflow text
      textOverflow: 'ellipsis', // Show ellipsis for overflowing text
    },
    noStoreMessage: {
      textAlign: 'center',
      fontSize: '18px',
      color: '#555',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <img src={logotwo} alt="Logo" style={styles.logo} /> {/* Logo added here */}
      <div style={styles.searchBarContainer}>
        <input 
          type="text" 
          placeholder="Search..." 
          style={styles.searchBar} 
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div style={styles.content}>
        <div style={styles.imageContainer}>
          {filteredStores.length > 0 ? (
            filteredStores.map(store => (
              <div key={store.mail}>
                <div 
                  style={styles.imageWrapper} 
                  onClick={() => handleStoreClick(store.store_name)}
                >
                  <img 
                    src={store.image_url || Logo} 
                    alt={store.store_name}
                    style={styles.imageStyle}
                  />
                </div>
                <div style={styles.storeNameContainer}>
                  <div style={styles.storeName}>{store.store_name}</div>
                </div>
              </div>
            ))
          ) : (
            <div style={styles.noStoreMessage}>No stores available.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
