import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultProfilePic from '../coblack.png'; // Default fallback image

const EditSlots = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '20px',
    overflowY: 'auto',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    maxWidth: '200px',
    cursor: 'pointer',
    flexShrink: 0,
  };

  const textStyle = {
    fontSize: '0.9rem',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const buttonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white',
    boxSizing: 'border-box',
  };

  const fetchAppointments = async () => {
    setLoading(true);
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch(`http://192.168.29.80:5001/get-appointments?email=${email}`);
      const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-appointments?email=${email}`);
      if (response.ok) {
        const data = await response.json();
        const appointmentData = data.map((appointment) => ({
          id: appointment.id,
          title: appointment.title,
          date: appointment.date,
          time: appointment.time,
          image: appointment.image_url || defaultProfilePic,
          slots: appointment.slots,
        }));
        setAppointments(appointmentData);
      } else {
        setMessage('No appointments found or failed to fetch appointments.');
      }
    } catch (error) {
      setMessage('Error fetching appointments: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const handleEditClick = (appointment) => {
    navigate('/editslotform', {
      state: {
        title: appointment.title,
        date: appointment.date,
        time: appointment.time,
        slots: appointment.slots,
        image: appointment.image, // Ensure consistent image handling
      },
    });
  };

  return (
    <div style={containerStyle}>
      <h2>Edit Appointments</h2>
      {loading && <p>Loading appointments...</p>}
      {message && <p style={{ color: 'red' }}>{message}</p>}
      <div style={imageContainerStyle}>
        {appointments.map((appointment, index) => (
          <div key={index} style={{ textAlign: 'center', margin: '10px' }}>
            <img src={appointment.image} alt={`Appointment ${index + 1}`} style={imageStyle} />
            <p style={textStyle}>Title: {appointment.title}</p>
            <p style={textStyle}>Date: {appointment.date}</p>
            <p style={textStyle}>Time: {appointment.time}</p>
            <p style={textStyle}>Slots: {appointment.slots}</p>
            <button onClick={() => handleEditClick(appointment)} style={buttonStyle}>
              Edit
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditSlots;
