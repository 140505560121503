import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const FetchAppointments = ({ email, selectedAppointments, handleAppointmentQuantityChange, quantities }) => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const navigate = useNavigate(); // Initialize navigate hook

 // Fetching appointments data
 useEffect(() => {
  const fetchAppointments = async () => {
    try {
      const response = await fetch(
        // `http://192.168.29.80:5001/get-appointments?email=${email}`
        `https://cosmyk.uc.r.appspot.com/get-appointments?email=${email}`
      );
      if (response.ok) {
        const data = await response.json();
        setAppointments(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch appointments');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    }
  };

  if (email) fetchAppointments();
}, [email]);

  // Function to render dots under images
  const renderDots = (imageCount, activeIndex) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        {Array.from({ length: imageCount }, (_, index) => (
          <div key={index} style={{
            height: '5px',
            width: '5px',
            borderRadius: '50%',
            backgroundColor: activeIndex === index ? 'black' : '#ccc',
            margin: '0 5px',
          }} />
        ))}
      </div>
    );
  };

  // Handling the image click to open the lightbox
  const handleImageClick = (index, images) => {
    setLightboxImages(images);
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const nextImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex + 1) % lightboxImages.length);
  };

  const prevImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + lightboxImages.length) % lightboxImages.length);
  };

  const imageContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
  };

  const productTextStyle = {
    fontSize: '1rem',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const orderButtonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white', 
    boxSizing: 'border-box',
  };
  
  const orderButtonDisabledStyle = {
    ...orderButtonStyle,
    backgroundColor: '#ccc', // Gray color for disabled button
    cursor: 'not-allowed',
  };

  

  const handleBookSlot = (appointment) => {
    if (appointment.slots > 0) {
      // Navigate to the appointment summary page with the appointment details
      navigate('/appointment-summary', { state: { appointment, email } });
    }
  };
  
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
     {error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : appointments.length === 0 ? (
        <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No Slots available </p> 
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {appointments.map((appointment, index) => (
            <div key={index}>
              {/* Image for appointment */}
              <div style={imageContainerStyle}>
                <img
                  src={appointment.image_url}
                  alt={appointment.name || 'Appointment'}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                    border: '2px solid #ddd',
                    margin: '10px',
                    maxWidth: '200px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(0, [appointment.image_url])} // Click opens lightbox
                />
              </div>
              {appointment.image_url && renderDots(1, 0)} {/* Dots for one image */}
              <div style={{ padding: '10px' }}>
                <p style={productTextStyle}>
                  {appointment.title.length > 20 ? `${appointment.title.slice(0, 20)}...` : appointment.title}
                </p>
                <p style={productTextStyle}>Date & Time : {appointment.date}, {appointment.time}</p>
                <p style={productTextStyle}>Location : {appointment.location} </p>
                <p style={productTextStyle}>Slots Available : {appointment.slots}</p>
                <p style={productTextStyle}>More Details : {appointment.description}</p>
                {/* Book Slot Button */}
                {appointment.slots > 0 ? (
                  <button
                    style={{
                      padding: '5px 10px',
                      backgroundColor: 'black',
                      color: 'white',
                      borderRadius: '100px',
                      cursor: 'pointer',
                      margin: '0 5px',
                      textAlign: 'center',
                      fontSize: '0.8rem',
                      transition: 'background-color 0.2s ease',
                      border: '2px solid white',
                      boxSizing: 'border-box',
                    }}
                    onClick={() => handleBookSlot(appointment)} // Navigate to summary page
                  >
                    Book Slot
                  </button>
                ) : (
                  <div style={orderButtonDisabledStyle}>Out of Slots</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Lightbox for image view */}
      {isLightboxOpen && lightboxImages.length > 0 && (
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center',
          alignItems: 'center', zIndex: 1000
        }} onClick={closeLightbox}>
          <img src={lightboxImages[lightboxIndex]} alt="Selected Appointment"
            style={{ maxWidth: '90%', maxHeight: '80%', borderRadius: '8px' }} />
          {lightboxImages.length > 1 && (
            <>
              <div style={{
                position: 'absolute', left: '5%', color: 'white', fontSize: '2rem',
                cursor: 'pointer'
              }} onClick={(e) => { e.stopPropagation(); prevImage(); }}>&lt;</div>
              <div style={{
                position: 'absolute', right: '5%', color: 'white', fontSize: '2rem',
                cursor: 'pointer'
              }} onClick={(e) => { e.stopPropagation(); nextImage(); }}>&gt;</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FetchAppointments;
