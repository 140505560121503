import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation
import logo from '../cowhite.png'; // Adjust the path as needed

const CosmykHomePage = () => {
    // State for detecting screen width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Dynamic styles based on the window width
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: windowWidth <= 480 ? '40px' : windowWidth <= 768 ? '60px' : '80px',
            minHeight: '100vh',
            fontFamily: "'Roboto', sans-serif",
            backgroundColor: 'transparent', // Removed background
        },
        logo: {
            width: windowWidth <= 480 ? '180px' : windowWidth <= 768 ? '200px' : '300px',
            height: 'auto',
            marginBottom: '20px',
            // filter: 'drop-shadow(0px 4px 6px rgba(0,0,0,0.1))', // Soft shadow for premium feel
        },
        tagline: {
            fontSize: windowWidth <= 480 ? '1.25rem' : windowWidth <= 768 ? '1.5rem' : '2rem',
            fontWeight: 'bold',
            color: '#333',
            marginBottom: '10px',
            letterSpacing: '1px', // Subtle letter spacing for elegance
        },
        description: {
            fontSize: windowWidth <= 480 ? '0.85rem' : windowWidth <= 768 ? '0.9rem' : '1rem',
            color: '#666',
            marginBottom: '20px',
            lineHeight: '1.5',
            textAlign: 'center',
        },
        ctaButton: {
            backgroundColor: 'black',
            border: '2px solid white', // Add a border color
            color: 'white',
            padding: '10px 30px', // Adjust padding for a more oval shape
            fontSize: '1rem',
            cursor: 'pointer',
            borderRadius: '100px', // Increase border radius for a more oval shape
            transition: 'background-color 0.3s',
            boxSizing: 'border-box',
        },
        ctaButtonHover: {
            backgroundColor: '#333', // Darken the background on hover
        },
        featuresSection: {
            width: '80%',
            maxWidth: '1200px',
            margin: '0 auto',
            textAlign: 'center',
            marginTop: '50px',
        },
        featureGrid: {
            display: 'grid',
            gridTemplateColumns: windowWidth <= 480 ? '1fr' : windowWidth <= 768 ? 'repeat(auto-fit, minmax(250px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
        },
        featureCard: {
            padding: windowWidth <= 480 ? '12px' : windowWidth <= 768 ? '15px' : '20px',
            borderRadius: '10px',
            border: '1px solid #ddd',
            backgroundColor: '#fff', // White background for feature cards
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)', // Soft box shadow for depth
            transition: 'box-shadow 0.3s, transform 0.2s',
            textAlign: 'center',
        },
        featureCardHover: {
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Stronger shadow on hover
            transform: 'scale(1.05)', // Slightly larger on hover
        },
        featureTitle: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: '#333',
            marginBottom: '10px',
        },
        featureDescription: {
            fontSize: '1rem',
            color: '#666',
        },
        footer: {
            marginTop: '50px',
            padding: '20px',
            color: '#666',
            textAlign: 'center',
            fontSize: windowWidth <= 480 ? '0.7rem' : windowWidth <= 768 ? '0.8rem' : '0.9rem',
            borderTop: '1px solid #ddd', // Subtle border on top of footer for separation
        },
        sectionButton: {
            backgroundColor: 'black',
            border: '2px solid white',
            color: 'white',
            padding: '10px 30px',
            fontSize: '1rem',
            cursor: 'pointer',
            borderRadius: '100px',
            transition: 'background-color 0.3s',
            boxSizing: 'border-box',
        },
        sectionButtonHover: {
            backgroundColor: '#333',
        },
    };

    return (
        <div style={styles.container}>
            {/* Hero Section */}
            <div>
                {/* <p style={styles.tagline}>Cosmyk.In</p> */}
                <img src={logo} alt="Cosmyk Logo" style={styles.logo} />
                <p style={styles.tagline}>Welcome to Cosmyk.In!</p>
                <p style={styles.description}>
                    Discover amazing products and services tailored for you. Log in to explore more!
                </p>
                <Link to="/login">
                    <button
                        style={styles.ctaButton}
                        onMouseOver={(e) => (e.target.style.backgroundColor = styles.ctaButtonHover.backgroundColor)}
                        onMouseOut={(e) => (e.target.style.backgroundColor = styles.ctaButton.backgroundColor)}
                    >
                        Login Now
                    </button>
                </Link>
            </div>

            {/* Features Section */}
            <div style={styles.featuresSection}>
                <h2 style={styles.tagline}>Our Features</h2>
                <div style={styles.featureGrid}>
                    <div
                        style={Object.assign({}, styles.featureCard, styles.featureCardHover)}
                        onMouseOver={(e) => (e.target.style.boxShadow = styles.featureCardHover.boxShadow)}
                        onMouseOut={(e) => (e.target.style.boxShadow = styles.featureCard.boxShadow)}
                    >
                        <h3 style={styles.featureTitle}>For Store Owners</h3>
                        <p style={styles.featureDescription}>
                            Easily add your store, products, and appointment slots to reach a wider audience.
                        </p>
                    </div>
                    <div
                        style={Object.assign({}, styles.featureCard, styles.featureCardHover)}
                        onMouseOver={(e) => (e.target.style.boxShadow = styles.featureCardHover.boxShadow)}
                        onMouseOut={(e) => (e.target.style.boxShadow = styles.featureCard.boxShadow)}
                    >
                        <h3 style={styles.featureTitle}>For Users</h3>
                        <p style={styles.featureDescription}>
                            Discover stores, buy exclusive products, and book appointments all in one place.
                        </p>
                    </div>
                    <div
                        style={Object.assign({}, styles.featureCard, styles.featureCardHover)}
                        onMouseOver={(e) => (e.target.style.boxShadow = styles.featureCardHover.boxShadow)}
                        onMouseOut={(e) => (e.target.style.boxShadow = styles.featureCard.boxShadow)}
                    >
                        <h3 style={styles.featureTitle}>Manage Appointments</h3>
                        <p style={styles.featureDescription}>
                            Store owners can manage their appointments while users can book easily.
                        </p>
                    </div>
                </div>
            </div>

            {/* Section above Footer */}
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <p style={styles.description}>
                    Discover amazing products and services tailored for you. Log in to explore more!
                </p>
                <Link to="/login">
                    <button
                        style={Object.assign({}, styles.sectionButton, styles.sectionButtonHover)}
                        onMouseOver={(e) => (e.target.style.backgroundColor = styles.sectionButtonHover.backgroundColor)}
                        onMouseOut={(e) => (e.target.style.backgroundColor = styles.sectionButton.backgroundColor)}
                    >
                        Login Now
                    </button>
                </Link>
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                © 2024 Cosmyk. All Rights Reserved.
            </footer>
        </div>
    );
};

export default CosmykHomePage;
