import React, { useState, useEffect } from 'react';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';

// Main AddAppointment Component
const AddAppointment = () => {
  const [isStoreSetup, setIsStoreSetup] = useState(false);
  const [storeStatus, setStoreStatus] = useState(null);

  const handleStoreSetup = (status) => {
    setIsStoreSetup(true);
    setStoreStatus(status);
  };

  return (
    <div>
      {storeStatus === 'store approved' ? (
        <AppointmentForm />
      ) : (
        <SetupStore onStoreSetup={handleStoreSetup} />
      )}
      {storeStatus === 'store requested' && <p>Your store is pending approval. Please wait.</p>}
    </div>
  );
};

// SetupStore Component
const SetupStore = ({ onStoreSetup }) => {
  const [storeName, setStoreName] = useState('');
  const [storeEmail, setStoreEmail] = useState('');
  const [storeMobile, setStoreMobile] = useState('');
  const [storeImage, setStoreImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestStatus, setRequestStatus] = useState('');
  const [storeStatus, setStoreStatus] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const email = localStorage.getItem('userEmail');
        if (!email) {
          console.error('No email found in localStorage.');
          setLoading(false);
          return;
        }

        // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
        const data = await response.json();

        if (response.ok) {
          setStoreName(data.name);
          setStoreEmail(email);
          setStoreMobile(data.mobile || '');

          // const storeStatusResponse = await fetch(`http://192.168.29.80:5001/fetch-store-status?email=${email}`);
          const storeStatusResponse = await fetch(`https://cosmyk.uc.r.appspot.com/fetch-store-status?email=${email}`);
          const storeStatusData = await storeStatusResponse.json();

          if (storeStatusResponse.ok) {
            setStoreStatus(storeStatusData.status);
            if (storeStatusData.status === 'store approved') {
              onStoreSetup('store approved');
            } else {
              setRequestStatus(`Your store setup is currently: ${storeStatusData.status}`);
            }
          } else {
            console.error('Error fetching store status:', storeStatusData.error);
          }
        } else {
          console.error('Error from API:', data.error);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [onStoreSetup]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStoreImage(file);
    }
  };

  const handleStoreSetup = async () => {
    if (!storeName || !storeEmail || !storeMobile || !storeImage) {
      alert('Please fill out all fields and upload a store image.');
      return;
    }

    const formData = new FormData();
    formData.append('email', storeEmail);
    formData.append('store_image', storeImage);

    try {
      // const response = await fetch('http://192.168.29.80:5001/request-store', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/request-store',
          {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setStoreStatus('store requested');
        setRequestStatus('Store setup requested successfully. Please wait for approval.');
      } else {
        setRequestStatus(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error requesting store setup:', error);
      setRequestStatus('An error occurred while requesting store setup.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>Setup your store to add appointments</h6>
      {storeStatus === 'store approved' ? (
        <p>Your store is approved. You can now add appointments.</p>
      ) : storeStatus === 'store requested' ? (
        <p>Your store setup is pending approval. Please wait.</p>
      ) : (
        <>
          <div style={styles.card}>
            <input
              type="text"
              style={styles.input}
              value={storeName}
              readOnly
              placeholder="Store Name"
            />
          </div>
          <div style={styles.card}>
            <input
              type="email"
              style={styles.input}
              value={storeEmail}
              readOnly
              placeholder="Email"
            />
          </div>
          <div style={styles.card}>
            <input
              type="text"
              style={styles.input}
              value={storeMobile}
              readOnly
              placeholder="Mobile Number"
            />
          </div>
          <button style={styles.button} onClick={() => document.getElementById('storeImageUpload').click()}>
            Upload Store Image / Logo
          </button>
          <input
            id="storeImageUpload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />

          {storeImage && (
            <div style={styles.imagePreviewContainer}>
              <img src={URL.createObjectURL(storeImage)} alt="Store Logo" style={styles.imagePreview} />
            </div>
          )}

          <button style={styles.button} onClick={handleStoreSetup}>
            Request Store Setup
          </button>

          {requestStatus && <p style={styles.confirmation}>{requestStatus}</p>}
        </>
      )}
    </div>
  );
};

// AppointmentForm Component
const AppointmentForm = () => {
  const [appointmentTitle, setAppointmentTitle] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [numberOfSlots, setNumberOfSlots] = useState('');
  const [appointmentDescription, setAppointmentDescription] = useState('');
  const [appointmentImage, setAppointmentImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [appointmentPrice, setAppointmentPrice] = useState(''); // Price state
  const [appointmentLocation, setAppointmentLocation] = useState(''); // Location state


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAppointmentImage(file);
    }
  };

  const handleAddAppointment = async () => {
    if (!appointmentTitle || !appointmentDate || !appointmentTime || !appointmentDescription || !numberOfSlots) {
      alert('Please fill out all fields.');
      return;
    }

    const email = localStorage.getItem('userEmail');
    if (!email) {
      alert('User email is not available.');
      return;
    }

    const formData = new FormData();
    formData.append('title', appointmentTitle);
    formData.append('date', appointmentDate);
    formData.append('time', appointmentTime);
    formData.append('description', appointmentDescription);
    formData.append('slots', numberOfSlots); // Added numberOfSlots to formData
    formData.append('email', email);
    formData.append('price', appointmentPrice);  // Added price to formData
    formData.append('location', appointmentLocation);  // Added location to formData

    if (appointmentImage) {
      formData.append('appointment_image', appointmentImage);
    }

    try {
      // const response = await fetch('http://192.168.29.80:5001/add-appointment', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/add-appointment',
          {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        alert('Appointment added successfully!');
        setShowConfirmation(true);
        resetForm();
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error adding appointment:', error);
      alert('An error occurred while adding the appointment.');
    }
  };

  const resetForm = () => {
    setAppointmentTitle(''); // Clears the title input field
    setAppointmentDate(''); // Clears the date input field
    setAppointmentTime(''); // Clears the time input field
    setAppointmentDescription(''); // Clears the description input field
    setNumberOfSlots(''); // Clears the number of slots input field
    setAppointmentImage(null); // Clears the uploaded image
    setAppointmentPrice(''); // Clears the price input field
    setAppointmentLocation(''); // Clears the location input field
  };
  

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>Add Appointment / Slots</h6>
      <div style={styles.card}>
        <input
          type="text"
          style={styles.input}
          value={appointmentTitle}
          onChange={(e) => setAppointmentTitle(e.target.value)}
          placeholder="Appointment / Slot Title"
        />
      </div>
      <div style={styles.card}>
        <input
          type="date"
          style={styles.input}
          value={appointmentDate}
          onChange={(e) => setAppointmentDate(e.target.value)}
          placeholder="Appointment Date"
        />
      </div>
      <div style={styles.card}>
        <input
          type="time"
          style={styles.input}
          value={appointmentTime}
          onChange={(e) => setAppointmentTime(e.target.value)}
          placeholder="Appointment Time"
        />
      </div>
      <div style={styles.card}>
  <input
    type="number"
    style={styles.input}
    value={appointmentPrice}
    onChange={(e) => setAppointmentPrice(e.target.value)}
    placeholder="Price"
  />
</div>
<div style={styles.card}>
  <input
    type="text"
    style={styles.input}
    value={appointmentLocation}
    onChange={(e) => setAppointmentLocation(e.target.value)}
    placeholder="Location"
  />
</div>

      <div style={styles.card}>
        <input
          type="number"
          style={styles.input}
          value={numberOfSlots}
          onChange={(e) => setNumberOfSlots(e.target.value)}
          placeholder="Number of Slots"
        />
      </div>
      
      <div style={styles.card}>
        <textarea
          style={styles.input}
          value={appointmentDescription}
          onChange={(e) => setAppointmentDescription(e.target.value)}
          placeholder="Appointment / Slot Description"
        />
      </div>

      <button style={styles.button} onClick={() => document.getElementById('appointmentImageUpload').click()}>
        Upload Appointment Image
      </button>
      <input
        id="appointmentImageUpload"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />

      {appointmentImage && (
        <div style={styles.imagePreviewContainer}>
          <img src={URL.createObjectURL(appointmentImage)} alt="Appointment Image" style={styles.imagePreview} />
        </div>
      )}

      <button style={styles.button} onClick={handleAddAppointment}>
        Add Appointment
      </button>
      {showConfirmation && <p style={styles.confirmation}>Appointment added successfully!</p>}
    </div>
  );
};

// Styles (No changes here)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    padding: '20px',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: 'black',
    border: '2px solid white',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    boxSizing: 'border-box',
    marginBottom: '10px',
  },
  imagePreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginRight: '10px',
  },
  confirmation: {
    marginTop: '20px',
    color: 'black',
    textAlign: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginTop: '-40px',
    marginBottom: '-20px',
  },
};

export default AddAppointment;