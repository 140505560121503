import React, { useState, useEffect } from 'react';
import logo from '../cowhite.png'; // Adjust the path as necessary
import axios from 'axios'; // Ensure axios is installed

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState('Pending Appointments');
  const [pendingOrders, setPendingOrders] = useState([]);
  const [dispatchedOrders, setDispatchedOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [totalProductCount, setTotalProductCount] = useState(0); // New state for total product count

  // Update salesData structure to match the new API response
  const [salesData, setSalesData] = useState({
    today: { label: '', sales: 0 },
    yesterday: { label: '', sales: 0 },
    month: { label: '', sales: 0 },
    year: { label: '', sales: 0 },
  });

  // Fetch products function
  const fetchAppointments = async () => {
    const userEmail = localStorage.getItem('userEmail'); // Retrieve the user email from localStorage
    if (!userEmail) {
      console.error('User email not found in local storage');
      return;
    }

    try {
      // const response = await axios.get(`http://192.168.29.80:5001/get-appointments`, 
        const response = await axios.get(`https://cosmyk.uc.r.appspot.com/get-appointments`, 
          {
        params: { email: userEmail }, // Pass user_email dynamically
      });

      // Count the total number of products
      const appointments = response.data; // Get the products array from the response
      setTotalProductCount(appointments.length); // Set the total count of products
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (selectedTab === 'Appointments Listed') {
        fetchAppointments(); // Fetch products when tab is selected
    }
  }, [selectedTab]);

  const fetchSalesData = async () => {
    const userEmail = localStorage.getItem('userEmail'); 
    try {
      // const response = await axios.get(`http://192.168.29.80:5001/appointments-sales-data`, 
        const response = await axios.get(`https://cosmyk.uc.r.appspot.com/appointments-sales-data`,
           {
        params: { user_email: userEmail },
      });
      setSalesData(response.data); // Update to set the new structured data
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  useEffect(() => {
    if (selectedTab === 'Sales') {
      fetchSalesData();
    }
  }, [selectedTab]);

  // Fetch orders function
  const fetchAppointmentOrders = async () => {
    const userEmail = localStorage.getItem('userEmail'); // Retrieve the user email from localStorage
    if (!userEmail) {
      console.error('User email not found in local storage');
      return;
    }

    try {
      // const response = await axios.get(`http://192.168.29.80:5001/get-booked-appointments`, 
        const response = await axios.get(`https://cosmyk.uc.r.appspot.com/get-booked-appointments`,
          {
        params: { user_email: userEmail }, // Pass user_email dynamically
      });

      const allAppointments = response.data.received_appointments;
      const filteredPendingOrders = allAppointments.filter(appointment => appointment.status === 'pending');
      const filteredDispatchedOrders = allAppointments.filter(appointment => appointment.status === 'confirmed');
      const filteredCompletedOrders = allAppointments.filter(appointment => appointment.status === 'completed');
      

      setPendingOrders(filteredPendingOrders);
      setDispatchedOrders(filteredDispatchedOrders);
      setCompletedOrders(filteredCompletedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchAppointmentOrders(); // Call fetchOrders when the component mounts
  }, []);

  const styles = {
    dashboardWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '-20px',
      minHeight: '100vh',
      overflowY: 'auto', // Allows vertical scrolling for the entire dashboard
    },
    container: {
      padding: '10px 20px',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      maxWidth: '800px', // Set a max width to keep the content centered
      width: '100%', // Ensure the container takes the full width
    },
    heading: {
      margin: '5px 0',
      textAlign: 'center',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '5px',
    },
    tabsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '15px',
    },
    tab: (isSelected) => ({
      backgroundColor: isSelected ? '#C5CAE9' : '#E8EAF6',
      padding: '8px 15px',
      borderRadius: '5px',
      margin: '5px',
      cursor: 'pointer',
      border: isSelected ? '2px solid #3f51b5' : 'none',
      textAlign: 'center',
      flex: '1 0 25%',
      fontSize: '14px',
      transition: 'background-color 0.3s',
    }),
    orderCard: {
      border: '1px solid #ddd',
      borderRadius: '5px',
      padding: '10px',
      margin: '10px 0',
      backgroundColor: '#f9f9f9',
    },
    orderDetail: {
      margin: '5px 0',
      fontSize: '12px',
      textAlign: 'left',
    },
    buttonStyle: {
      padding: '5px 10px',
      backgroundColor: 'black',
      color: 'white',
      borderRadius: '100px',
      cursor: 'pointer',
      margin: '5px 0',
      textAlign: 'center',
      fontSize: '0.8rem',
      transition: 'background-color 0.2s ease',
      border: '2px solid white',
      boxSizing: 'border-box',
    },
    salesCard: {
      flex: '1 0 45%', // Adjust to control size
      border: '2px solid',
      borderRadius: '8px',
      padding: '15px',
      margin: '10px',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      ':hover': {
        transform: 'scale(1.02)',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
      },
    },
    salesLabel: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#555',
    },
    salesAmount: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333',
    },
  };

  const handleConfirmAppointment = async (appointmentId) => {
    try {
      // const response = await axios.post('http://192.168.29.80:5001/confirm-appointment', 
        const response = await axios.post('https://cosmyk.uc.r.appspot.com/confirm-appointment',
          {
        appointment_id: appointmentId,
      });
      alert(response.data.message); // Display success message
      fetchAppointmentOrders(); // Re-fetch orders to update the dashboard
    } catch (error) {
      console.error("Error dispatching order:", error);
      alert(error.response.data.error); // Display error message
    }
  };

  const handleCompleteAppointment = async (appointmentId) => {
    try {
      // const response = await axios.post('http://192.168.29.80:5001/complete-appointment',
        const response = await axios.post('https://cosmyk.uc.r.appspot.com/complete-appointment', 
          {
        appointment_id: appointmentId,
      });
      alert(response.data.message); // Display success message
      fetchAppointmentOrders(); // Re-fetch orders to update the dashboard
    } catch (error) {
      console.error("Error completing order:", error);
      alert(error.response.data.error); // Display error message
    }
  };

  return (
    <div style={styles.dashboardWrapper}>
      <div style={styles.container}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h6 style={styles.heading}>Appointment Dashboard</h6>
        <div style={styles.tabsContainer}>
          <div
            style={styles.tab(selectedTab === 'Pending Appointments')}
            onClick={() => setSelectedTab('Pending Appointments')}
          >
            Pending Appointments ({pendingOrders.length}) {/* Display count here */}
          </div>
          <div
            style={styles.tab(selectedTab === 'Confirmed Appointments')}
            onClick={() => setSelectedTab('Confirmed Appointments')}
          >
            Confirmed Appointments ({dispatchedOrders.length})
          </div>
          <div
            style={styles.tab(selectedTab === 'Completed Appointments')}
            onClick={() => setSelectedTab('Completed Appointments')}
          >
            Completed Appointments ({completedOrders.length})
          </div>
          <div
            style={styles.tab(selectedTab === 'Total Appointments')}
            onClick={() => setSelectedTab('Total Appointments')}
          >
            Total Appointments ({pendingOrders.length + dispatchedOrders.length + completedOrders.length})
          </div>
          <div
            style={styles.tab(selectedTab === 'Appointments Listed')}
            onClick={() => setSelectedTab('Appointments Listed')}
          >
            Appointments Listed
          </div>
          <div
            style={styles.tab(selectedTab === 'Sales')}
            onClick={() => setSelectedTab('Sales')}
          >
            Sales
          </div>
        </div>
        <div style={{ marginTop: '10px', fontSize: '16px' }}>
          {selectedTab}
        </div>

        {selectedTab === 'Pending Appointments' && (
          <div>
            {pendingOrders.length > 0 ? (
              pendingOrders.map(appointment => (
                <div key={appointment._id} style={styles.orderCard}>
                  <div style={styles.orderDetail}>Order ID: {appointment._id}</div>
                  <div style={styles.orderDetail}>User Name: {appointment.user_name}</div>
                  <div style={styles.orderDetail}>Appointment Title: {appointment.appointment_title}</div>
                  <div style={styles.orderDetail}>Appointment Date: {appointment.appointment_date}</div>
                  <div style={styles.orderDetail}>Appointment Time: {appointment.appointment_time}</div>
                  <div style={styles.orderDetail}>Slots Booked: {appointment.slots_booked}</div>
                  <div style={styles.orderDetail}>Members: {appointment.members && appointment.members.length > 0 ? (
    appointment.members.map((member, index) => (
      <div key={index}>
        Name: {member.name}, Phone: {member.phone}
      </div>
    ))
  ) : (
    "No members"
  )}</div>
                  <div style={styles.orderDetail}>Total Amount: {appointment.total_amount}</div>
                  <div style={styles.orderDetail}>Created At: {new Date(appointment.created_at).toLocaleString()}</div>
                  <div style={styles.orderDetail}>Status: {appointment.status}</div>
                  <button
                    style={styles.buttonStyle}
                    onClick={() => handleConfirmAppointment(appointment._id)} // Call function with order ID
                  >
                    Confirm Appointment
                  </button>
                </div>
              ))
            ) : (
              <div style={styles.orderDetail}>No Pending Appointments.</div>
            )}
          </div>
        )}

        {selectedTab === 'Confirmed Appointments' && (
          <div>
            {dispatchedOrders.length > 0 ? (
              dispatchedOrders.map(appointment => (
                <div key={appointment._id} style={styles.orderCard}>
                <div style={styles.orderDetail}>Order ID: {appointment._id}</div>
                  <div style={styles.orderDetail}>User Name: {appointment.user_name}</div>
                  <div style={styles.orderDetail}>Appointment Title: {appointment.appointment_title}</div>
                  <div style={styles.orderDetail}>Appointment Date: {appointment.appointment_date}</div>
                  <div style={styles.orderDetail}>Appointment Time: {appointment.appointment_time}</div>
                  <div style={styles.orderDetail}>Slots Booked: {appointment.slots_booked}</div>
                  <div style={styles.orderDetail}>Members: {appointment.members && appointment.members.length > 0 ? (
    appointment.members.map((member, index) => (
      <div key={index}>
        Name: {member.name}, Phone: {member.phone}
      </div>
    ))
  ) : (
    "No members"
  )}</div>
                  <div style={styles.orderDetail}>Total Amount: {appointment.total_amount}</div>
                  <div style={styles.orderDetail}>Confirmed At: {new Date(appointment.confirmed_at).toLocaleString()}</div>
                  <div style={styles.orderDetail}>Status: {appointment.status}</div>
                  <button
                    style={styles.buttonStyle}
                    onClick={() => handleCompleteAppointment(appointment._id)} // Call function with order ID
                  >
                    Complete Appointment
                  </button>
                </div>
              ))
            ) : (
              <div style={styles.orderDetail}>No Confirmed Appointments.</div>
            )}
          </div>
        )}

        {selectedTab === 'Completed Appointments' && (
          <div>
            {completedOrders.length > 0 ? (
              completedOrders.map(appointment => (
                <div key={appointment._id} style={styles.orderCard}>
                  <div style={styles.orderDetail}>Order ID: {appointment._id}</div>
                  <div style={styles.orderDetail}>User Name: {appointment.user_name}</div>
                  <div style={styles.orderDetail}>Appointment Title: {appointment.appointment_title}</div>
                  <div style={styles.orderDetail}>Appointment Date: {appointment.appointment_date}</div>
                  <div style={styles.orderDetail}>Appointment Time: {appointment.appointment_time}</div>
                  <div style={styles.orderDetail}>Slots Booked: {appointment.slots_booked}</div>
                  <div style={styles.orderDetail}>Members: {appointment.members && appointment.members.length > 0 ? (
    appointment.members.map((member, index) => (
      <div key={index}>
        Name: {member.name}, Phone: {member.phone}
      </div>
    ))
  ) : (
    "No members"
  )}</div>
                  <div style={styles.orderDetail}>Total Amount: {appointment.total_amount}</div>
                  <div style={styles.orderDetail}>Completed At: {new Date(appointment.completed_at).toLocaleString()}</div>
                  <div style={styles.orderDetail}>Status: {appointment.status}</div>
                </div>
              ))
            ) : (
              <div style={styles.orderDetail}>No Completed Appointments.</div>
            )}
          </div>
        )}

        {selectedTab === 'Total Appointments' && (
          <div>
            <h6 style={styles.orderDetail}>Total Appointments: {pendingOrders.length + dispatchedOrders.length + completedOrders.length}</h6>
          </div>
        )}

{selectedTab === 'Appointments Listed' && (
          <div>
            <h6 style={styles.orderDetail}>Total Appointments Listed: {totalProductCount}</h6>
          </div>
        )}


{selectedTab === 'Sales' && (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
    <h6 style={styles.orderDetail}>Appointment Sales Overview</h6>
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '600px', flexWrap: 'wrap' }}>
      <div style={{ ...styles.salesCard, borderColor: '#3f51b5' }}>
        <h6 style={styles.salesLabel}>{salesData.today.label}</h6>
        <p style={styles.salesAmount}>₹ {salesData.today.sales}</p>
      </div>
      <div style={{ ...styles.salesCard, borderColor: '#ff9800' }}>
        <h6 style={styles.salesLabel}>{salesData.yesterday.label}</h6>
        <p style={styles.salesAmount}>₹ {salesData.yesterday.sales}</p>
      </div>
      <div style={{ ...styles.salesCard, borderColor: '#4caf50' }}>
        <h6 style={styles.salesLabel}>{salesData.month.label}</h6>
        <p style={styles.salesAmount}>₹ {salesData.month.sales}</p>
      </div>
      <div style={{ ...styles.salesCard, borderColor: '#f44336' }}>
        <h6 style={styles.salesLabel}>{salesData.year.label}</h6>
        <p style={styles.salesAmount}>₹ {salesData.year.sales}</p>
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default Dashboard;