import React from 'react';
import BottomNavbar from './BottomNavbar';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
    const navigate = useNavigate();

    const handleDashboardClick = () => {
        navigate('/myorders');
    };

    const handleHomeClick = () => {
        navigate('/myappointments');
    };

    const styles = {
        container: {
            padding: '10px',
            maxWidth: '800px',
            margin: '0 auto',
        },
        title: {
            textAlign: 'center',
            marginBottom: '10px',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        note: {
            textAlign: 'center',
            fontSize: '14px',
            color: '#444',
            margin: '10px 0',
        },
        button: {
            padding: '5px 10px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '100px',
            cursor: 'pointer',
            margin: '0 5px',
            textAlign: 'center',
            fontSize: '0.8rem',
            transition: 'background-color 0.2s ease',
            border: '2px solid white',
            boxSizing: 'border-box',
        },
    };

    return (
        <div style={styles.container}>
            <h6 style={styles.title}>Store Orders & Appointments</h6>

            <p style={styles.note}>Please click on Store Orders button to see Received and Placed Orders</p>
            <button style={styles.button} onClick={handleDashboardClick}>Store Orders</button>

            <p style={styles.note}>Please click on Appointment Orders button to see Received and Placed Appointments</p>
            <button style={styles.button} onClick={handleHomeClick}>Appointment Orders</button>

            <BottomNavbar />
        </div>
    );
};

export default Orders;