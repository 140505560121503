import React, { useEffect, useState } from 'react';
import BottomNavbar from './BottomNavbar';
import axios from 'axios'; // Ensure axios is installed
import { useNavigate } from 'react-router-dom';

const MyOrders = () => {
    const [orders, setOrders] = useState({ placed: [], received: [] });
    const [selectedTab, setSelectedTab] = useState('Received Orders');
    const navigate = useNavigate();

    const handleDashboardClick = () => {
        navigate('/dashboard');
      };

      const handleHomeClick = () => {
        navigate('/');
      };

    const fetchUserOrders = async () => {
        const userEmail = localStorage.getItem('userEmail');
        if (!userEmail) {
            console.error('User email not found in local storage');
            return;
        }

        console.log('Fetching orders for:', userEmail);
        // const response = await fetch(`http://192.168.29.80:5001/fetch-orders?user_email=${userEmail}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/fetch-orders?user_email=${userEmail}`);
        if (response.ok) {
            const data = await response.json();
            console.log('Fetched Orders Data:', data);
            if (Array.isArray(data.placed_orders) && Array.isArray(data.received_orders)) {
                setOrders({
                    placed: data.placed_orders,
                    received: data.received_orders
                });
            } else {
                console.error('Unexpected data format:', data);
            }
        } else {
            console.error('Error fetching orders:', response.status);
        }
    };

    useEffect(() => {
        fetchUserOrders();
    }, []);

    const filterReceivedOrders = (ordersList) => {
        // Filter for orders that are either pending or dispatched
        return ordersList.filter(order => order.status === 'pending' || order.status === 'dispatched');
    };

    const sortPlacedOrders = (ordersList) => {
        // Sort placed orders by created_at date in descending order
        return ordersList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };

    const renderOrderList = (ordersList, isReceived = false) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {ordersList.length > 0 ? ordersList.map((order) => (
                    <div key={order._id} style={styles.orderCard}>
                        <div style={styles.orderDetail}>Order ID: {order._id}</div>
                        <div style={styles.orderDetail}>User Name: {isReceived ? order.user_name : ''}</div>
                        {order.ordered_products.map((product, index) => (
                            <div key={index} style={styles.orderDetail}>
                                Product Name: <strong>{product.product_name}</strong> (x{product.quantity}) 
                                <span style={{ marginLeft: '10px' }}>Total: ₹{product.total_amount || product.total}</span>
                            </div>
                        ))}
                        {isReceived ? (
                            <div style={styles.orderDetail}>
                                User Mobile: <strong>{order.user_mobile}</strong>
                                <br />
                                Address: {`${order.user_address?.house_number}, ${order.user_address?.street}, ${order.user_address?.city}, ${order.user_address?.state}, ${order.user_address?.pincode}`}
                            </div>
                        ) : (
                            <div style={styles.orderDetail}>Store Name: <strong>{order.store_name}</strong></div>
                        )}
                        <div style={styles.orderDetail}>Status: <strong>{order.status}</strong></div>
                        <div style={styles.orderDetail}>Created At: {new Date(order.created_at).toLocaleDateString()}</div>
                    </div>
                )) : <p>No orders found.</p>}
            </div>
        );
    };

    const styles = {
        orderCard: {
            border: '1px solid #ddd',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
        orderDetail: {
            margin: '5px 0',
            fontSize: '12px',
            textAlign: 'left',
        },
        note: {
            textAlign: 'center',
            fontSize: '12px',  // Increased font size for better visibility
            color: '#444',     // Darker color for better visibility
            margin: '10px 0',  // Added margin for spacing
        },
        button: {
            padding: '5px 10px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '100px',
            cursor: 'pointer',
            margin: '0 5px',
            textAlign: 'center',
            fontSize: '0.8rem',
            transition: 'background-color 0.2s ease',
            border: '2px solid white',
            boxSizing: 'border-box',
          },
    };

    return (
        <div style={{ padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
            <h6 style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold', fontSize: '16px' }}>My Orders</h6>
            <p style={{ textAlign: 'center', marginTop: '10px', color: '#777', fontSize: '12px' }}>Powered by cosmyk.in</p>

            {/* Order Tab Selection */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <div 
                    style={{
                        cursor: 'pointer',
                        textDecoration: selectedTab === 'Received Orders' ? 'underline' : 'none',
                        margin: '0 5px',
                        fontSize: '14px',
                        fontWeight: selectedTab === 'Received Orders' ? 'bold' : 'normal',
                    }} 
                    onClick={() => setSelectedTab('Received Orders')}
                >
                    Received Orders
                </div>
                <div 
                    style={{
                        cursor: 'pointer',
                        textDecoration: selectedTab === 'Placed Orders' ? 'underline' : 'none',
                        margin: '0 5px',
                        fontSize: '14px',
                        fontWeight: selectedTab === 'Placed Orders' ? 'bold' : 'normal',
                    }} 
                    onClick={() => setSelectedTab('Placed Orders')}
                >
                    Placed Orders
                </div>
            </div>

            {/* Render the selected order type */}
            {selectedTab === 'Received Orders' 
                ? (
                    <>
                        <p style={styles.note}>please click on Store Dashboard button for more actions and options for Received Orders</p>
                        <button style={styles.button} onClick={handleDashboardClick} >Store Dashboard</button>
                        {renderOrderList(filterReceivedOrders(orders.received), true)}
                    </>
                )
                
                : 
                <>
                <p style={styles.note}>please click on Stores Button to explore more stores and continue shopping</p>
                <button style={styles.button} onClick={handleHomeClick} >Stores</button>
                {renderOrderList(sortPlacedOrders(orders.placed))}
                </>
}
            <BottomNavbar />
        </div>
    );
};

export default MyOrders;
