import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DeleteProduct = () => {
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '20px',
    overflowY: 'auto',
  };

  const headerStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    maxWidth: '200px',
    cursor: 'pointer',
    flexShrink: 0,
  };

  const productNameStyle = {
    fontSize: '0.8rem',
    color: '#333',
    margin: '5px 0',
  };

  const buttonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white',
    boxSizing: 'border-box',
  };

  const fetchProductImages = async () => {
    setLoading(true);
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch(`http://192.168.29.80:5001/get-products?email=${email}`);
      const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-products?email=${email}`);
      console.log('Fetch response:', response); // Log the response for debugging
      if (response.ok) {
        const products = await response.json();
        console.log('Products fetched:', products); // Log the products data
        const images = products.map(product => ({
          id: product.id, // Include product ID for deletion
          name: product.name,
          stock: product.stock_quantity,
          images: product.image_urls,
          price: product.price,
        })).filter(product => product.images.length > 0);
        setProductImages(images);
      } else {
        setMessage('No products found or failed to fetch products.');
      }
    } catch (error) {
      setMessage('Error fetching products: ' + error.message);
      console.error('Error fetching products:', error); // Log the error
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchProductImages();
  }, []);

  const handleDeleteClick = async (productName) => {
    const email = localStorage.getItem('userEmail');
    if (window.confirm(`Are you sure you want to delete ${productName}?`)) {
      try {
        // const response = await fetch(`http://192.168.29.80:5001/delete-product?email=${email}&product_name=${productName}`,
          const response = await fetch(`https://cosmyk.uc.r.appspot.com/delete-product?email=${email}&product_name=${productName}`,
             {
          method: 'DELETE',
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        setMessage(`${productName} deleted successfully!`);
        await fetchProductImages(); // Refresh product list
      } catch (error) {
        setMessage('Error deleting product: ' + error.message);
      }
    }
  };

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Delete Products</h2>
      {loading ? <p>Loading products...</p> : null}
      {message && <p style={{ color: 'red' }}>{message}</p>}
      <div style={imageContainerStyle}>
        {productImages.map((product, index) => (
          <div key={index} style={{ textAlign: 'center', margin: '10px' }}>
            <img 
              src={product.images[0]} 
              alt={`Product ${index + 1}`} 
              style={imageStyle} 
            />
            <p style={productNameStyle}>Product name: {product.name}</p>
            <p style={productNameStyle}>Price: ₹ {product.price.toFixed(2)}</p>
            <p style={productNameStyle}>Stock: {product.stock}</p>
            <button onClick={() => handleDeleteClick(product.name)} style={buttonStyle}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeleteProduct;
