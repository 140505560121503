import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const OrderSummaryContainer = styled.div`
  padding: 30px;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const ProductList = styled.div`
  margin: 10px 0;
`;

const ProductItem = styled.div`
  margin: 15px 0;
  padding: 15px;
  background: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
`;

const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 8px;
`;

const ProductDetails = styled.div`
  flex: 1;
`;

const ProductName = styled.p`
  font-weight: 600;
  margin: 0;
  font-size: 14px;
`;

const ProductPrice = styled.p`
  margin: 5px 0 0;
  color: #333;
  font-size: 12px;
`;

const ProductQuantity = styled.p`
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
`;

const TotalAmount = styled.h3`
  font-weight: 700;
  color: #2b8a3c;
  margin-top: 10px;
  font-size: 16px;
`;

const UserDetails = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: #333;
`;

const FeesContainer = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: #333;
`;

const FeesHeader = styled.h6`
  margin: 0 0 10px;
  font-weight: 600;
`;

const FeeItem = styled.p`
  margin: 0;
`;

const Button = styled.button`
background-color: black;
  margin-top: 20px;
  padding: 12px;
  color: white;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
`;

const PlaceOrderButton = styled(Button)`
  background-color: #2b8a3c;
`;

const BackButton = styled(Button)`
  background-color: black;
`;

const AddAddressButton = styled(Button)`
  background-color: #007bff; /* Blue color for the button */
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;  // Ensure modal doesn't stretch too wide
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    max-width: 90%;  // Adjust for smaller screens
  }
`;

const ModalHeader = styled.h6`
  margin: 0 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #333;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.2s ease;

  ::placeholder {
    color: #999;
    font-style: italic;
  }

  &:focus {
    border-color: #2b8a3c;  // Focus border color for better interaction feedback
  }
`;

const CloseButton = styled.button`
 background-color: black;
  margin-top: 20px;
  padding: 12px;
  color: white;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
`;

const OrderSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedProducts } = location.state || { selectedProducts: [] };
  const [user, setUser] = useState(null);
  const [fees, setFees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState({
    houseNumber: '',
    street: '',
    city: '',
    state: '',
    pincode: '',
  });

  const [isAddressSubmitted, setIsAddressSubmitted] = useState(false);

  const totalAmount = selectedProducts.reduce((sum, product) => sum + product.total, 0);
  const totalFees = fees.reduce((sum, fee) => sum + Number(fee.feevalue), 0);
  const grandTotal = totalAmount + totalFees; // Calculate grand total
  

  const handlePlaceOrder = async () => {
    const { houseNumber, street, city, state, pincode } = address;
  
    if (!houseNumber || !street || !city || !state || !pincode) {
      alert('Please fill in all address fields before placing the order.');
      return;
    }
  
    if (!/^\d+$/.test(pincode)) {
      alert('Please enter all address fields.');
      return;
    }
  
    const orderData = {
      store_email: selectedProducts[0]?.storeEmail,
      user_email: user?.email,
      ordered_products: selectedProducts.map((product) => ({
          product_name: product.name,
          quantity: product.quantity,
          total: product.total, // Ensure total is included
      })),
      user_address: {
          house_number: houseNumber,
          street,
          city,
          state,
          pincode,
      },
      total_amount: grandTotal, // Include grand total
  };
  
    
    try {
      // const response = await fetch('http://192.168.29.80:5001/place-order',
        const response = await fetch('https://cosmyk.uc.r.appspot.com/place-order', 
          {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert('Order placed successfully!');
        sessionStorage.removeItem(`selectedProducts_${selectedProducts[0]?.storeName}`);
        navigate('/', { replace: true }); // use replace option here
      } else {
        alert(result.error || 'Failed to place order');
      }
    } catch (error) {
      console.error(error);
      alert('An error occurred while placing the order.');
    }
  };
  

  const handleAddAddress = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Restrict pincode to numeric values only
  if (name === 'pincode' && !/^\d*$/.test(value)) {
    return; // Ignore any non-numeric input
  }
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitAddress = () => {
    if (!/^\d+$/.test(address.pincode)) {
      alert('Please enter all address fields.');
      return;
    }
    setIsAddressSubmitted(true);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const email = localStorage.getItem('userEmail');
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchFees = async () => {
      const email = localStorage.getItem('userEmail');
      if (selectedProducts.length > 0) {
        try {
          // const response = await fetch(`http://192.168.29.80:5001/fetch-fees?user_email=${selectedProducts[0]?.storeEmail}`);
          const response = await fetch(`https://cosmyk.uc.r.appspot.com/fetch-fees?user_email=${selectedProducts[0]?.storeEmail}`);
          if (!response.ok) {
            throw new Error('Failed to fetch fees');
          }
          const feesData = await response.json();
          // Check if the store names match
          if (feesData.store_name === selectedProducts[0]?.storeName) {
            setFees(feesData.fees);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUserDetails();
    fetchFees();
  }, [selectedProducts]);

  return (
    <OrderSummaryContainer>
      <Header>
        <h6>Order Summary</h6>
      </Header>
      {selectedProducts.length > 0 ? (
        <section>
          <h6 style={{ textAlign: 'center', fontSize: '14px' }}>Store Name: {selectedProducts[0]?.storeName}</h6>
          <h6 style={{ textAlign: 'center', fontSize: '14px' }}>Store Email: {selectedProducts[0]?.storeEmail}</h6>
          <ProductList>
            {selectedProducts.map((product, index) => (
              <ProductItem key={index}>
                <ProductImage src={product.imageUrl} alt={product.name} />
                <ProductDetails>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>Price: ₹{product.price.toFixed(2)}</ProductPrice>
                  <ProductQuantity>Quantity: {product.quantity}</ProductQuantity>
                  <ProductPrice>Total: ₹{product.total.toFixed(2)}</ProductPrice>
                </ProductDetails>
              </ProductItem>
            ))}
          </ProductList>
          <FeesContainer>
            <FeesHeader>Fees:</FeesHeader>
            {fees.length > 0 ? (
              fees.map((fee, index) => (
                <FeeItem key={index}>{fee.feename}: ₹{fee.feevalue}</FeeItem>
              ))
            ) : (
              <FeeItem>No additional fees</FeeItem>
            )}
          </FeesContainer>
          <TotalAmount>Grand Total: ₹{grandTotal.toFixed(2)}</TotalAmount>
          <UserDetails>
            {user && (
              <div>
                <p>User Email: {user.email}</p>
                <p>Name: {user.name}</p>
              </div>
            )}
          </UserDetails>
          <AddAddressButton onClick={handleAddAddress}>Add Address</AddAddressButton>
          <PlaceOrderButton onClick={handlePlaceOrder}>Place Order</PlaceOrderButton>
          <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
        </section>
      ) : (
        <p>No products selected.</p>
      )}
      {isModalOpen && (
        <ModalOverlay>
          <ModalContainer>
            <ModalHeader>Add Address</ModalHeader>
            <Input
              type="text"
              name="houseNumber"
              placeholder="House Number"
              value={address.houseNumber}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="street"
              placeholder="Street"
              value={address.street}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="city"
              placeholder="City"
              value={address.city}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="state"
              placeholder="State"
              value={address.state}
              onChange={handleChange}
            />
            <Input
  type="text"  // Optionally, change to "number" if you want to use a numeric input type
  name="pincode"
  placeholder="Pincode"
  value={address.pincode}
  onChange={handleChange}
/>

            <Button onClick={handleSubmitAddress}>Submit Address</Button>
            <CloseButton onClick={handleCloseModal}>Close</CloseButton>
          </ModalContainer>
        </ModalOverlay>
      )}
    </OrderSummaryContainer>
  );
};

export default OrderSummary;
