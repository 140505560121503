import React, { useState, useEffect } from 'react';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';
import { useLocation, useNavigate } from 'react-router-dom';

const EditProductForm = () => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productStock, setProductStock] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate for routing
  const { productId, name, price, stock } = location.state || {}; // Get product data from state

  useEffect(() => {
    setProductName(name);
    setProductPrice(price);
    setProductStock(stock);
  }, [name, price, stock]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-product?id=${productId}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-product?id=${productId}`);
        const data = await response.json();

        if (response.ok) {
          setProductName(data.name);
          setProductPrice(data.price);
          setProductStock(data.stock);
        } else {
          console.error('Error fetching product details:', data.error);
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleUpdateProduct = async () => {
    if (!productName || !productPrice || !productStock) {
      alert('Please fill out all fields.');
      return;
    }

    const formData = new FormData();
    formData.append('email', localStorage.getItem('userEmail')); // Assuming user email is stored in local storage
    formData.append('product_name', productName);
    formData.append('product_price', productPrice);
    formData.append('stock_quantity', productStock);
    formData.append('product_id', productId); // Include product ID for the update

    try {
      // const response = await fetch('http://192.168.29.80:5001/update-product', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/update-product',
           {
        method: 'PUT', // Change from POST to PUT
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        alert('Product updated successfully!');
        setShowConfirmation(true);
        navigate('/editproduct'); // Navigate to the update product page after successful update
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error updating product:', error);
      alert('An error occurred while updating the product.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>Edit Product</h6>
      <div style={styles.card}>
        <input
          type="text"
          style={styles.input}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          disabled
          placeholder="Product Name"
        />
      </div>
      <div style={styles.card}>
        <input
          type="number"
          style={styles.input}
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          placeholder="Product Price"
        />
      </div>
      <div style={styles.card}>
        <input
          type="number"
          style={styles.input}
          value={productStock}
          onChange={(e) => setProductStock(e.target.value)}
          placeholder="Stock Quantity"
        />
      </div>
      <button style={styles.button} onClick={handleUpdateProduct}>
        Update Product
      </button>
      {showConfirmation && <p style={styles.confirmation}>Product updated successfully!</p>}
    </div>
  );
};

// Styles (unchanged)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    padding: '20px',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: 'black',
    border: '2px solid white',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    boxSizing: 'border-box',
    marginBottom: '10px',
  },
  confirmation: {
    marginTop: '20px',
    color: 'black',
    textAlign: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginTop: '-40px',
    marginBottom: '-20px',
  },
};

export default EditProductForm;
