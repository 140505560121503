import React, { useEffect, useState } from 'react';
import BottomNavbar from './BottomNavbar';
import axios from 'axios'; // Ensure axios is installed
import { useNavigate } from 'react-router-dom';

const MyAppointments = () => {
    const [appointments, setAppointments] = useState({ placed: [], received: [] });
    const [selectedTab, setSelectedTab] = useState('Received Appointments');
    const navigate = useNavigate();

    const handleDashboardClick = () => {
        navigate('/appointmentsdashboard');
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    const fetchUserAppointments = async () => {
        const userEmail = localStorage.getItem('userEmail');
        if (!userEmail) {
            console.error('User email not found in local storage');
            return;
        }

        console.log('Fetching appointments for:', userEmail);
        // const response = await fetch(`http://192.168.29.80:5001/get-booked-appointments?user_email=${userEmail}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-booked-appointments?user_email=${userEmail}`);
        if (response.ok) {
            const data = await response.json();
            console.log('Fetched Appointments Data:', data);
            if (Array.isArray(data.placed_appointments) && Array.isArray(data.received_appointments)) {
                setAppointments({
                    placed: data.placed_appointments,
                    received: data.received_appointments
                });
            } else {
                console.error('Unexpected data format:', data);
            }
        } else {
            console.error('Error fetching appointments:', response.status);
        }
    };

    useEffect(() => {
        fetchUserAppointments();
    }, []);

    const filterReceivedAppointments = (appointmentsList) => {
        // Filter for appointments that are either pending or confirmed
        return appointmentsList.filter(appointment => appointment.status === 'pending' || appointment.status === 'confirmed');
    };

    const sortPlacedAppointments = (appointmentsList) => {
        // Sort placed appointments by created_at date in descending order
        return appointmentsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };

    const renderAppointmentList = (appointmentsList, isReceived = false) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {appointmentsList.length > 0 ? appointmentsList.map((appointment) => (
                    <div key={appointment._id} style={styles.appointmentCard}>
                        <div style={styles.appointmentDetail}>Appointment ID: {appointment._id}</div>
                        <div style={styles.appointmentDetail}>Appointment Title: <strong>{appointment.appointment_title}</strong></div>
                        <div style={styles.appointmentDetail}>Store Name: {appointment.store_name}</div>
                        <div style={styles.appointmentDetail}>Appointment Date: {new Date(appointment.appointment_date).toLocaleDateString()}</div>
                        <div style={styles.appointmentDetail}>Appointment Time: {appointment.appointment_time}</div>
                        <div style={styles.appointmentDetail}>Location: {appointment.location}</div>
                        <div style={styles.appointmentDetail}>Status: <strong>{appointment.status}</strong></div>
                        <div style={styles.appointmentDetail}>Created At: {new Date(appointment.created_at).toLocaleDateString()}</div>
                        {isReceived && (
                            <>
                                <div style={styles.appointmentDetail}>User Mobile: <strong>{appointment.user_mobile}</strong></div>
                                <div style={styles.appointmentDetail}>Address: {appointment.user_address?.house_number}, {appointment.user_address?.street}, {appointment.user_address?.city}, {appointment.user_address?.state}, {appointment.user_address?.pincode}</div>
                            </>
                        )}
                    </div>
                )) : <p>No appointments found.</p>}
            </div>
        );
    };

    const styles = {
        appointmentCard: {
            border: '1px solid #ddd',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
        appointmentDetail: {
            margin: '5px 0',
            fontSize: '12px',
            textAlign: 'left',
        },
        note: {
            textAlign: 'center',
            fontSize: '12px',
            color: '#444',
            margin: '10px 0',
        },
        button: {
            padding: '5px 10px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '100px',
            cursor: 'pointer',
            margin: '0 5px',
            textAlign: 'center',
            fontSize: '0.8rem',
            transition: 'background-color 0.2s ease',
            border: '2px solid white',
            boxSizing: 'border-box',
        },
    };

    return (
        <div style={{ padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
            <h6 style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold', fontSize: '16px' }}>My Appointments</h6>
            <p style={{ textAlign: 'center', marginTop: '10px', color: '#777', fontSize: '12px' }}>Powered by cosmyk.in</p>

            {/* Appointment Tab Selection */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <div
                    style={{
                        cursor: 'pointer',
                        textDecoration: selectedTab === 'Received Appointments' ? 'underline' : 'none',
                        margin: '0 5px',
                        fontSize: '14px',
                        fontWeight: selectedTab === 'Received Appointments' ? 'bold' : 'normal',
                    }}
                    onClick={() => setSelectedTab('Received Appointments')}
                >
                    Received Appointments
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                        textDecoration: selectedTab === 'Placed Appointments' ? 'underline' : 'none',
                        margin: '0 5px',
                        fontSize: '14px',
                        fontWeight: selectedTab === 'Placed Appointments' ? 'bold' : 'normal',
                    }}
                    onClick={() => setSelectedTab('Placed Appointments')}
                >
                    Placed Appointments
                </div>
            </div>

            {/* Render the selected appointment type */}
            {selectedTab === 'Received Appointments' ? (
                <>
                    <p style={styles.note}>Please click on Appointment Dashboard button for more actions and options for Received Appointments</p>
                    <button style={styles.button} onClick={handleDashboardClick}>Appointment Dashboard</button>
                    {renderAppointmentList(filterReceivedAppointments(appointments.received), true)}
                </>
            ) : (
                <>
                    <p style={styles.note}>Please click on Stores Button to explore more stores and continue your appointments</p>
                    <button style={styles.button} onClick={handleHomeClick}>Stores</button>
                    {renderAppointmentList(sortPlacedAppointments(appointments.placed))}
                </>
            )}
            <BottomNavbar />
        </div>
    );
};

export default MyAppointments;
