import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FeedPage = ({ email }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Pagination state
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
        //   `http://192.168.29.80:5001/get-feed-posts?limit=${limit}&offset=${offset}`
          `https://cosmyk.uc.r.appspot.com/get-feed-posts?limit=${limit}&offset=${offset}`
        );
        console.log('Response Status:', response.status);  // Log status
        const data = await response.json();
        console.log('Response Data:', data);  // Log the returned data

        if (response.ok) {
          // Shuffle posts in frontend (optional, if you want to randomize more)
          const shuffledPosts = data.sort(() => Math.random() - 0.5);
          setPosts(shuffledPosts);
        } else {
          setError('Failed to fetch posts');
        }
      } catch (error) {
        setError('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [limit, offset]);

  const handlePostClick = (post) => {
    // navigate('/post-details', { state: { post, email } });
  };

  const handleViewStoreClick = (storeName) => {
    navigate(`/${storeName}`); // Navigate to the store details page using store_name
  };

  // Function to check if the URL is a video
  const isVideo = (url) => {
    return url && (url.endsWith('.mp4') || url.includes('youtube') || url.includes('vimeo'));
  };

  // Render Dots for image slideshows
  const renderDots = (mediaCount, activeIndex) => (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3px' }}>
      {Array.from({ length: mediaCount }, (_, index) => (
        <div
          key={index}
          style={{
            height: '3px',
            width: '3px',
            borderRadius: '50%',
            backgroundColor: activeIndex === index ? 'black' : '#ccc',
            margin: '0 3px',
          }}
        />
      ))}
    </div>
  );

  // Function to render media (images or videos)
  const renderMedia = (mediaUrl, index) => {
    const extension = mediaUrl.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: 'auto',
            background: `url(${mediaUrl}) center center no-repeat`,
            backgroundSize: 'contain',
            borderRadius: '6px',
            marginBottom: '5px',
            overflow: 'hidden',
            aspectRatio: '9/16',
          }}
        />
      );
    } else if (['mp4', 'webm', 'avi'].includes(extension)) {
      return (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: 'auto',
            backgroundColor: 'black',
            borderRadius: '6px',
            overflow: 'hidden',
            marginBottom: '5px',
            aspectRatio: '9/16',
          }}
        >
          <video
            id={`video-${index}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '6px',
            }}
            controls
          controlsList="nodownload nofullscreen noplaybackrate"

          >
            <source src={mediaUrl} type={`video/${extension}`} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading && <p>Loading posts...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px', // Adjust space between posts
          width: '100%',
        }}
      >
        {posts.length === 0 ? (
          <p>No posts available</p>
        ) : (
          posts.map((post, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                maxWidth: '600px', // Adjusted width for better layout
                cursor: 'pointer',
                backgroundColor: '#fff',
                marginBottom: '15px',
                padding: '15px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for a clean look
                transition: 'transform 0.3s',
              }}
              onClick={() => handlePostClick(post)}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <div>{renderMedia(post.media_urls[0], index)}</div>
              {post.media_urls.length > 1 && renderDots(post.media_urls.length, 0)}

              <div style={{ padding: '10px 0' }}>
                <h3 style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>{post.title}</h3>
                <p style={{ color: '#666', fontSize: '14px', marginTop: '5px' }}>{post.description}</p>
              </div>

              {/* View Store Button */}
              <div style={{ paddingTop: '10px' }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent post click navigation
                    handleViewStoreClick(post.store_name); // Navigate to store page using store_name
                  }}
                  style={{
                    backgroundColor: 'black',
                    border: '2px solid white', // Add a border color
                    color: 'white',
                    padding: '10px 30px', // Adjust padding for a more oval shape
                    fontSize: '1rem',
                    cursor: 'pointer',
                    borderRadius: '100px', // Increase border radius for a more oval shape
                    transition: 'background-color 0.3s',
                    boxSizing: 'border-box',
                  }}
                >
                  View Store
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FeedPage;
