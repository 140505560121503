import React, { useState, useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import LoginPage from './Pages/LoginPage';
import BottomNavbar from './Pages/BottomNavbar';
import ProfileScreen from './Pages/ProfileScreen';
import Dashboard from './Pages/Dashboard';
import AddAppointment from './Pages/AddAppointment';
import AddProduct from './Pages/AddProduct';
import MainPage from './Pages/MainPage';
import StorePage from './Pages/StorePage';
import logo from './cowhite.png'; // Import the logo
import logotwo from './coblack.png';
import OrderSummary from './Pages/OrderSummary';
import MyOrders from './Pages/MyOrders';
import Menu from './Pages/Menu';
import EditProduct from './Pages/EditProduct';
import EditProductForm from './Pages/EditProductFrom';
import DeleteProduct from './Pages/DeleteProduct';
import AddRemoveFee from './Pages/AddRemoveFee';
import AppointmentSummary from './Pages/AppointmentSummary';
import CosmykHomePage from './Pages/CosmykHomePage';
import EditSlots from './Pages/EditSlots';
import EditSlotsForm from './Pages/EditSlotsForm';
import DeleteSlots from './Pages/DeleteSlots';
import MyAppointments from './Pages/MyAppointments';
import Orders from './Pages/Orders';
import AppointmentsDashboard from './Pages/AppointmentDashboard';
import AddPost from './Pages/AddPost';
import FeedPage from './Pages/Feed';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  const styles = {
    app: {
      textAlign: 'center',
      backgroundColor: 'white',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2rem',
      color: 'black',
      boxSizing: 'border-box',
    },
    navbar: {
      backgroundColor: 'black',
      color: 'white',
      width: '100%',
      padding: '15px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
      flexWrap: 'wrap',
      zIndex: 1000,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logoImageSmall: {
      width: '50px', // Adjust size as needed for the navbar
      height: 'auto',
      marginRight: '10px',
    },
    logoText: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: 'white',
    },
    navActions: {
      display: 'flex',
      alignItems: 'center',
    },
    loginButton: {
      backgroundColor: 'black',
      border: '2px solid white', // Add a border color
      color: 'white',
      padding: '10px 30px', // Adjust padding for a more oval shape
      fontSize: '1rem',
      cursor: 'pointer',
      borderRadius: '100px', // Increase border radius for a more oval shape
      transition: 'background-color 0.3s',
      boxSizing: 'border-box',
    },
    
    loginButtonHover: {
      backgroundColor: 'black',
    },
    mainContent: {
      marginTop: '100px',
      marginBottom: '60px',
    },
    centeredContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    loginMessage: {
      marginTop: '20px',
      fontSize: '1.25rem',
      textAlign: 'center',
    },
    logoImage: {
      width: '300px', // Adjust size as needed for the main page
      height: 'auto',
      marginBottom: '10px',
    },
  };
  
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <div style={styles.app}>
     <div style={styles.navbar}>
  <div style={styles.logoContainer}>
    <img src={logotwo} alt="Logo" style={styles.logoImageSmall} />
    <span style={styles.logoText}>cosmyk.in</span>
  </div>
  <div style={styles.navActions}>
    {isLoggedIn ? (
      <button
        style={styles.loginButton}
        onClick={handleLogout}
      >
        Logout
      </button>
    ) : (
      <Link to="/login">
        <button
          style={styles.loginButton}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.loginButtonHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.loginButton.backgroundColor)}
        >
          Login
        </button>
      </Link>
    )}
  </div>
</div>


      <div style={styles.mainContent}>
        <Routes>
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
          <Route 
  path="/" 
  element={
    isLoggedIn ? <MainPage /> : (
       <CosmykHomePage />
    )
  } 
/>
          {/* <Route 
            path="/orders" 
            element={
              isLoggedIn ? <h1>Orders Page</h1> : (
                <CosmykHomePage />
              )
            } 
          /> */}
          <Route 
            path="/profile" 
            element={
              isLoggedIn ? <ProfileScreen /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              isLoggedIn ? <Dashboard /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/add-product" 
            element={
              isLoggedIn ? <AddProduct /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/add-appointment" 
            element={
              isLoggedIn ? <AddAppointment /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/:storeName" 
            element={
              isLoggedIn ? <StorePage /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/ordersummary" 
            element={
              isLoggedIn ? <OrderSummary /> : (
                <CosmykHomePage />
              )
            } 
          />
           <Route 
            path="/myorders" 
            element={
              isLoggedIn ? <MyOrders /> : (
                <CosmykHomePage />
              )
            } 
          />
           <Route 
            path="/menu" 
            element={
              isLoggedIn ? <Menu /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/editproduct" 
            element={
              isLoggedIn ? <EditProduct /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/editproductform" 
            element={
              isLoggedIn ? <EditProductForm /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/deleteproduct" 
            element={
              isLoggedIn ? <DeleteProduct /> : (
                <CosmykHomePage />
              )
            } 
          />
           <Route 
            path="/addremovefee" 
            element={
              isLoggedIn ? <AddRemoveFee /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/appointment-summary" 
            element={
              isLoggedIn ? <AppointmentSummary /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/editslot" 
            element={
              isLoggedIn ? <EditSlots /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/editslotform" 
            element={
              isLoggedIn ? <EditSlotsForm /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/deleteslot" 
            element={
              isLoggedIn ? <DeleteSlots /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/myappointments" 
            element={
              isLoggedIn ? <MyAppointments /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/orders" 
            element={
              isLoggedIn ? <Orders /> : (
                <CosmykHomePage />
              )
            } 
          />
           <Route 
            path="/appointmentsdashboard" 
            element={
              isLoggedIn ? <AppointmentsDashboard /> : (
                <CosmykHomePage />
              )
            } 
          />
          <Route 
            path="/addpost" 
            element={
              isLoggedIn ? <AddPost /> : (
                <CosmykHomePage />
              )
            } 
          />
           <Route 
            path="/feed" 
            element={
              isLoggedIn ? <FeedPage /> : (
                <CosmykHomePage />
              )
            } 
          />
        </Routes>
      </div>

      {isLoggedIn && <BottomNavbar />}
    </div>
  );
}

export default App;
