import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const EditSlotsForm = () => {
  const [slotTitle, setSlotTitle] = useState('');
  const [slotDate, setSlotDate] = useState('');
  const [slotTime, setSlotTime] = useState('');
  const [slots, setSlots] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const locationState = useLocation();
  const navigate = useNavigate();
  const { title, date, time, slots: initialSlots, description: initialDescription, price: initialPrice, location: initialLocation, image: initialImage } = locationState.state || {};

  useEffect(() => {
    if (title && date && time && initialSlots) {
      setSlotTitle(title);
      setSlotDate(date);
      setSlotTime(time);
      setSlots(initialSlots);
      setDescription(initialDescription || '');
      setPrice(initialPrice || '');
      setLocation(initialLocation || '');
      setImage(initialImage || null);
    }
    setLoading(false);
  }, [title, date, time, initialSlots, initialDescription, initialPrice, initialLocation, initialImage]);

  const handleUpdateSlot = async () => {
    if (!slotTitle || !slotDate || !slotTime || !slots) {
      alert('Please fill out all required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('email', localStorage.getItem('userEmail'));
    formData.append('title', slotTitle);
    formData.append('date', slotDate);
    formData.append('time', slotTime);
    formData.append('slots', slots);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('location', location);

    if (image) {
      formData.append('appointment_image', image);
    }

    try {
      // const response = await fetch('http://192.168.29.80:5001/update-appointment',
        const response = await fetch('https://cosmyk.uc.r.appspot.com/update-appointment', 
          {
        method: 'PUT',
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        alert('Slot updated successfully!');
        setShowConfirmation(true);
        navigate('/editslot');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error updating slot:', error);
      alert('An error occurred while updating the slot.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <h6>Edit Slot</h6>
      <div style={styles.card}>
        <input type="text" style={styles.input} value={slotTitle} onChange={(e) => setSlotTitle(e.target.value)} placeholder="Slot Title" />
      </div>
      <div style={styles.card}>
        <input type="date" style={styles.input} value={slotDate} onChange={(e) => setSlotDate(e.target.value)} placeholder="Slot Date" />
      </div>
      <div style={styles.card}>
        <input type="time" style={styles.input} value={slotTime} onChange={(e) => setSlotTime(e.target.value)} placeholder="Slot Time" />
      </div>
      <div style={styles.card}>
        <input type="number" style={styles.input} value={slots} onChange={(e) => setSlots(e.target.value)} placeholder="Slots Available" />
      </div>
      <div style={styles.card}>
        <textarea style={styles.input} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
      </div>
      <div style={styles.card}>
        <input type="text" style={styles.input} value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
      </div>
      <div style={styles.card}>
        <input type="text" style={styles.input} value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Location" />
      </div>
      <div style={styles.card}>
        <input type="file" style={styles.input} onChange={(e) => setImage(e.target.files[0])} />
      </div>
      <div style={styles.card}>
        <button onClick={handleUpdateSlot} style={styles.button}>Save Changes</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  card: {
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
};

export default EditSlotsForm;
