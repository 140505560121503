import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultProfilePic from '../coblack.png'; // Default fallback image

const EditProduct = () => {
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '20px',
    overflowY: 'auto',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
    overflow: 'hidden', // Prevent horizontal scroll
  };

  const imageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    maxWidth: '200px',
    cursor: 'pointer',
    flexShrink: 0, // Prevent shrinking to maintain width
  };

  const productTextStyle = {
    fontSize: '0.9rem',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const orderButtonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white',
    boxSizing: 'border-box',
  };

  // Fetch product images and handle missing data
  const fetchProductImages = async () => {
    setLoading(true);
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch(`http://192.168.29.80:5001/get-products?email=${email}`);
      const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-products?email=${email}`);
      if (response.ok) {
        const products = await response.json();
        const images = products.map(product => ({
          name: product.name,
          stock: product.stock_quantity,
          images: product.image_urls.length > 0 ? product.image_urls : [defaultProfilePic], // Fix here: use image_urls
          price: product.price,
        }));
        setProductImages(images);
      } else {
        setMessage('No products found or failed to fetch products.');
      }
    } catch (error) {
      setMessage('Error fetching products: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchProductImages();
  }, []);

  const handleEditClick = (product) => {
    navigate('/editproductform', {
      state: {
        productId: product.id,
        name: product.name,
        price: product.price,
        stock: product.stock,
      },
    });
  };

  return (
    <div style={containerStyle}>
      <h2>Edit Products</h2>
      {loading && <p>Loading products...</p>}
      {message && <p style={{ color: 'red' }}>{message}</p>}
      <div style={imageContainerStyle}>
        {productImages.map((product, index) => (
          <div key={index} style={{ textAlign: 'center', margin: '10px' }}>
            <img 
              src={product.images[0]} 
              alt={`Product ${index + 1}`} 
              style={imageStyle} 
            />
            <p style={productTextStyle}>Product Name: {product.name}</p>
            <p style={productTextStyle}>Price: ₹ {product.price.toFixed(2)}</p>
            <p style={productTextStyle}>Stock Available: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
            <button onClick={() => handleEditClick(product)} style={orderButtonStyle}>Edit</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditProduct;
