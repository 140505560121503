import React, { useState, useEffect } from 'react';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';

// AddPost Component
const AddPost = () => {
  const [userName, setUserName] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [postContent, setPostContent] = useState('');
  const [postVideo, setPostVideo] = useState(null); // Store video
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const email = localStorage.getItem('userEmail');
        if (!email) {
          console.error('No email found in localStorage.');
          setLoading(false);
          return;
        }

        // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
        const data = await response.json();

        if (response.ok) {
          setUserName(data.name);
        } else {
          console.error('Error from API:', data.error);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];

    // Check if the selected file is a video
    if (file && file.type.startsWith('video/')) {
      setPostVideo({
        file,
        preview: URL.createObjectURL(file),
      });
    } else {
      alert('Please upload a valid video file.');
    }
  };

  const handleRemoveVideo = () => {
    setPostVideo(null);
  };

  const handleAddPost = async () => {
    if (!postTitle || !postContent || !postVideo) {
      alert('Please fill out all fields and upload a video.');
      return;
    }
  
    const email = localStorage.getItem('userEmail');
    if (!email) {
      alert('User email is not available.');
      return;
    }
  
    const formData = new FormData();
    formData.append('post_title', postTitle);
    formData.append('post_description', postContent);
    formData.append('email', email);
  
    // Corrected the field name to 'media_files' as expected by the backend
    formData.append('media_files', postVideo.file); // Send the video file with correct field name
  
    try {
      // const response = await fetch('http://192.168.29.80:5001/add-post', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/add-post', 
        
        {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
  
      if (response.ok) {
        alert('Post added successfully!');
        setShowConfirmation(true);
        resetForm();
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error adding post:', error);
      alert('An error occurred while adding the post.');
    }
  };
  

  const resetForm = () => {
    setPostTitle('');
    setPostContent('');
    setPostVideo(null);
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>Add Post</h6>
      <div style={styles.card}>
        <input
          type="text"
          style={styles.input}
          value={postTitle}
          onChange={(e) => setPostTitle(e.target.value)}
          placeholder="Post Title"
        />
      </div>
      <div style={styles.card}>
        <textarea
          style={styles.input}
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          placeholder="Post Content"
        />
      </div>
      <button style={styles.button} onClick={() => document.getElementById('postVideoUpload').click()}>
        Upload Video
      </button>
      <input
        id="postVideoUpload"
        type="file"
        accept="video/*"
        style={{ display: 'none' }}
        onChange={handleVideoUpload}
      />

      {postVideo && (
        <div style={styles.videoPreviewContainer}>
          <video width="200" controls>
            <source src={postVideo.preview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button style={styles.removeButton} onClick={handleRemoveVideo}>
            X
          </button>
        </div>
      )}

      <button style={styles.button} onClick={handleAddPost}>
        Add Post
      </button>
      {showConfirmation && <p style={styles.confirmation}>Post added successfully!</p>}
    </div>
  );
};

// Styles (Same as your original AddProduct styles)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    padding: '20px',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: 'black',
    border: '2px solid white',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    boxSizing: 'border-box',
    marginBottom: '10px',
  },
  videoPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  },
  removeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: '2px 5px',
  },
  confirmation: {
    marginTop: '20px',
    color: 'black',
    textAlign: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginTop: '-40px',
    marginBottom: '-20px',
  },
};

export default AddPost;
