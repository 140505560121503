import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavbar from './BottomNavbar';
import defaultProfilePic from '../coblack.png';

const ProfileScreen = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [editName, setEditName] = useState('');
  const [editMobile, setEditMobile] = useState('');
  const [selectedName, setSelectedName] = useState('Store'); // Changed from 'Customize Store'
  const [message, setMessage] = useState(''); // Adjusted message accordingly  
  const [productImages, setProductImages] = useState([]); // Updated to hold product images
  const [profilePicUrl, setProfilePicUrl] = useState(defaultProfilePic);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]); // To hold images for the selected product
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isPostLightboxOpen, setIsPostLightboxOpen] = useState(false);
  const [selectedPostMedia, setSelectedPostMedia] = useState(null);

  const handlePostClick = (media) => {
    setSelectedPostMedia(media);
    setIsPostLightboxOpen(true);
  };
  


  const fetchAppointments = async () => {
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch(`http://192.168.29.80:5001/get-appointments?email=${email}`);
      const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-appointments?email=${email}`);
      if (response.ok) {
        const appointmentsData = await response.json();
        setAppointments(appointmentsData);
      } else {
        console.error('No appointments found or failed to fetch appointments.');
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };

  const fetchPosts = async () => {
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch(`http://192.168.29.80:5001/get-posts?email=${email}`);
      const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-posts?email=${email}`);
      if (response.ok) {
        const postsData = await response.json();
        setPosts(postsData);
      } else {
        console.error('No posts found or failed to fetch posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    if (selectedName === 'Posts') {
      fetchPosts();
    }
  }, [selectedName]);
  
  const handleImageClick = (index, productIndex, isAppointment = false) => {
    if (isAppointment) {
      // When the clicked image is for an appointment
      setLightboxImages([appointments[productIndex].image_url]); // Only one image for appointments
    } else {
      // When the clicked image is for a product
      setLightboxImages(productImages[productIndex].images); // Get all images for the product
    }
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };
  
  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const nextImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex + 1) % lightboxImages.length);
  };

  const prevImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + lightboxImages.length) % lightboxImages.length);
  };

  const renderDots = (imageCount, activeIndex) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        {Array.from({ length: imageCount }, (_, index) => (
          <div key={index} style={{
            height: '5px',
            width: '5px',
            borderRadius: '50%',
            backgroundColor: activeIndex === index ? 'black' : '#ccc',
            margin: '0 5px',
          }} />
        ))}
      </div>
    );
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '20px',
    overflowY: 'auto',
  };

  const profileContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '150px',
    justifyContent: 'center',
  };

  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '10px',
  };

  const profilePicStyle = {
    width: '80px',
    height: '80px',
    marginRight: '20px',
    border: '2px solid #ddd',
    borderRadius: '50%', // Makes the image circular
};

  const nameStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
  };

  const subtitleStyle = {
    fontSize: '1rem',
    color: '#666',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    width: '100%',
    maxWidth: '300px',
  };

  const buttonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white', 
    boxSizing: 'border-box',
  };

  const namesContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%',
    maxWidth: '300px',
  };

  const nameStyleInRow = (name) => ({
    fontSize: '1rem',
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: selectedName === name ? 'underline' : 'none',
    margin: '0 10px',
  });

  const messageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
  };

 const lightboxOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const lightboxImageStyle = {
    maxWidth: '90%',
    maxHeight: '80%',
    borderRadius: '8px',
  };

  const navButtonStyle = {
    color: 'white',
    fontSize: '2rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    zIndex: 1001,
    padding: '10px',
  };

  const leftButtonStyle = { ...navButtonStyle, left: '5%' };
  const rightButtonStyle = { ...navButtonStyle, right: '5%' };

  const imageContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
  };

  const imageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    maxWidth: '200px',
    cursor: 'pointer',
  };

  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    padding: '30px 20px',
    zIndex: '1000',
    width: '90%',
    maxWidth: '350px',
    textAlign: 'center',
    overflow: 'hidden',                       // Ensures content stays within the popup
    boxSizing: 'border-box',
  };
  
  const inputStyle = {
    margin: '12px 0',
    padding: '12px',                          // Adjust padding for placeholder visibility
    width: '100%',
    borderRadius: '6px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    boxSizing: 'border-box',                  // Ensures padding doesn’t push the content out
    textAlign: 'center',                      // Centers placeholder text
  };
  
  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '999',
  };

  const productTextStyle = {
    fontSize: '0.9rem',  // Smaller font size
    color: '#333',
    whiteSpace: 'nowrap',  // Prevent text wrapping
    overflow: 'hidden',     // Hide overflow text
    textOverflow: 'ellipsis' // Show ellipsis for overflow
};

const postLightboxStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw', // Ensures it takes up 80% of the viewport width
  maxWidth: '90%',
  height: 'auto',
  maxHeight: '90vh',
  backgroundColor: 'black',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  overflow: 'hidden',
  aspectRatio: '9 / 16', // Ensure aspect ratio is maintained on mobile too
  '@media (max-width: 768px)': {
    width: '90vw', // Take up more space on mobile
    height: 'auto',
    maxHeight: '80vh', // Limit the height to prevent overflow
  }
};

const overlayStyleForPost = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 999,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: 'white',
  fontSize: '1.5rem',
  cursor: 'pointer',
  zIndex: 1001,
  '@media (max-width: 768px)': {
    top: '5px',
    right: '5px',
    fontSize: '1.2rem', // Make the close button smaller on mobile
  },
};

const reelContainerStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
  padding: '0 10px',
  gap: '10px', // Add gap between items for better alignment
};

const reelStyle = {
  width: 'auto',
  height: 'calc(60vh)', // Reduce the height to 50% of the viewport height
  aspectRatio: '9 / 16', // Maintain the 9:16 aspect ratio
  objectFit: 'cover',
  borderRadius: '8px',
  border: '2px solid #ddd',
  margin: '10px',
  maxWidth: '90vw', // Take up the full width of the viewport
  cursor: 'pointer',
  '@media (min-width: 768px)': {
    height: 'calc((100vw - 120px) / 5 * 16 / 9)',
    maxWidth: 'calc((100vw - 120px) / 5)',
  },
  '@media (min-width: 1024px)': {
    height: 'calc((100vw - 200px) / 6 * 16 / 9)',
    maxWidth: '200px',
    maxHeight: '356px',
  },
};

const getReelStyle = () => {
  const width = window.innerWidth;

  if (width >= 1024) { // Desktop
    return {
      ...reelStyle,
      height: '356px', // Fixed height for desktop
      maxWidth: '200px', // Fixed width for desktop
    };
  } else if (width >= 768) { // Tablet
    return {
      ...reelStyle,
      height: 'calc((100vw - 120px) / 5 * 16 / 9)',
      maxWidth: 'calc((100vw - 120px) / 5)',
    };
  }
  return reelStyle; // Mobile
};

  const handleEditNameChange = (e) => {
    setEditName(e.target.value.toLowerCase()); // Convert input to lowercase
  };
  
  const handleEditMobileChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Allow only digits
    if (numericValue.length <= 10) {
      setEditMobile(numericValue);
    }
  };

  const isSaveButtonEnabled = editName.trim() !== '' && editMobile.length === 10;

  const handleSave = async () => {
    const email = localStorage.getItem('userEmail');
    try {
      // const response = await fetch('http://192.168.29.80:5001/update-user-details',
        const response = await fetch('https://cosmyk.uc.r.appspot.com/update-user-details',
           {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name: editName,
          mobile: editMobile,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update user details');
      }

      const data = await response.json();
      console.log(data.message);
      setUser((prevUser) => ({ ...prevUser, name: editName, mobile: editMobile }));
      setIsEditPopupOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const email = localStorage.getItem('userEmail');
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        const data = await response.json();
        setUser(data);
        setEditName(data.name);
        setEditMobile(data.mobile);
      } catch (error) {
        console.error(error);
      }
    };
  
    const fetchProductImages = async () => {
      const email = localStorage.getItem('userEmail');
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-products?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-products?email=${email}`);
        if (response.ok) {
          const products = await response.json();
          console.log('Fetched products:', products); // Log the response here

          // Ensure each product has images before adding it to productImages
          const images = products.map(product => ({
            name: product.name,
            stock: product.stock_quantity,
            images: product.image_urls && product.image_urls.length > 0 ? product.image_urls : [], // Handle missing images
            price: product.price
          }));
    
          // If no product has images, show a fallback message instead of filtering out all products
          setProductImages(images);
        } else {
          console.error('No products found or failed to fetch products.');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchStoreLogo = async () => {
      const email = localStorage.getItem('userEmail'); // Get user email from local storage
      try {
        // const response = await fetch('http://192.168.29.80:5001/fetch-stores'); // Fetch store details
        const response = await fetch('https://cosmyk.uc.r.appspot.com/fetch-stores'); // Fetch store details
        if (!response.ok) {
          throw new Error('Failed to fetch store details');
        }
        const data = await response.json();
        
        // Find the store matching the user's email
        const store = data.stores.find(store => store.mail === email);
        
        // If store exists and has an image_url, set it; otherwise, use default
        if (store && store.image_url) {
          setProfilePicUrl(store.image_url);
        }
      } catch (error) {
        console.error('Error fetching store logo:', error);
      }
    };
  
    const fetchDetails = async () => {
      await Promise.all([fetchUserDetails(), fetchProductImages(), fetchStoreLogo(), fetchAppointments()]);
    };
  
    fetchDetails();
  }, []);
  
  const handleNameClick = (name) => {
    setSelectedName(name);
    if (name === 'Store') {
      setMessage('view store & products here');
    } else if (name === 'Slots') {
      setMessage('scheduled appointments or slots are here');
    } else if (name === 'Posts') {
      setMessage('view your posts here');
    }
  };
  

  return (
    <div style={containerStyle}>
      {/* Profile Section */}
      <div style={profileContainerStyle}>
        <header style={headerStyle}>
        <img src={profilePicUrl} alt="Profile" style={profilePicStyle} />
          <div style={{ textAlign: 'center' }}>
            <h2 style={nameStyle}>{user?.name}</h2>
            <p style={subtitleStyle}>powered by cosmyk.in</p>
          </div>
        </header>
        <div style={buttonContainerStyle}>
          <button style={buttonStyle} onClick={() => setIsEditPopupOpen(true)}>Edit Profile</button>
          <button style={buttonStyle} onClick={() => navigate('/dashboard')}>View Dashboard</button>
          <button style={buttonStyle} onClick={() => setIsAddPopupOpen(true)}>Add</button>
        </div>
      </div>

      {/* Names for Selecting */}
<div style={namesContainerStyle}>
<div 
    style={nameStyleInRow('Posts')} 
    onClick={() => handleNameClick('Posts')}
  >
    Add Posts
  </div>
  <div 
    style={nameStyleInRow('Store')} 
    onClick={() => handleNameClick('Store')}
  >
    Customize Store
  </div>
  <div 
    style={nameStyleInRow('Slots')} 
    onClick={() => handleNameClick('Slots')}
  >
    Slots
  </div>
 
</div>


      {/* Message and Image Display Section */}
      <div style={messageContainerStyle}>
        {/* <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>{message}</p> */}

       {/* Posts Section */}
{selectedName === 'Posts' && (
  <div style={reelContainerStyle}>
    {posts.length > 0 ? (
      posts.map((post, index) => (
        <div key={index} style={{ textAlign: 'center', margin: '10px' }}>
          <p style={productTextStyle}>{post.title}</p>
          {post.media_urls.map((url, idx) => (
            <div key={idx} onClick={() => handlePostClick(url)}>
              {url.endsWith('.mp4') ? (
                <video
                  controls
                  style={getReelStyle()}
                  controlsList="nodownload nofullscreen noplaybackrate"
                >
                  <source src={url} type="video/mp4" />
                </video>
              ) : (
                <img src={url} alt={`Post ${idx}`} style={getReelStyle()} />
              )}
            </div>
          ))}
        </div>
      ))
    ) : (
      <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No posts available, Please Add</p>
    )}
  </div>
)}

{isPostLightboxOpen && (
  <>
    <div style={overlayStyleForPost} onClick={() => setIsPostLightboxOpen(false)} />
    <div style={postLightboxStyle}>
      {selectedPostMedia.endsWith('.mp4') ? (
        <video 
          src={selectedPostMedia} 
          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
          controls 
          controlsList="nodownload nofullscreen noplaybackrate" 
          autoPlay 
        />
      ) : (
        <img 
          src={selectedPostMedia} 
          alt="Post Media" 
          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
        />
      )}
      <button 
        style={closeButtonStyle} 
        onClick={() => setIsPostLightboxOpen(false)}
      >
        ×
      </button>
    </div>
  </>
)}




      {/* Images for Customize Store */}
      {selectedName === 'Store' && (
  <div style={imageContainerStyle}>
    {productImages.length > 0 ? (
      productImages.map((product, productIndex) => (
        <div key={productIndex} style={{ textAlign: 'center', margin: '10px' }}>
          <img 
            src={product.images.length > 0 ? product.images[0] : defaultProfilePic} // Show default image if no product images
            alt={`Product ${productIndex + 1}`} 
            style={imageStyle} 
            onClick={() => handleImageClick(0, productIndex)} 
          />
          {renderDots(product.images.length, 0)} 
          <p style={productTextStyle}>{product.name}</p>
          <p style={productTextStyle}>Price: ₹ {product.price.toFixed(2)}</p>
          <p style={productTextStyle}>Stock Available: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
        </div>
      ))
    ) : (
      <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No products available, Please Add</p>  // Fallback message if no products
    )}
  </div>
)}

        {/* Lightbox for full-screen view */}
        {isLightboxOpen && lightboxImages.length > 0 && (
          <div style={lightboxOverlayStyle} onClick={closeLightbox}>
            <img src={lightboxImages[lightboxIndex]} alt="Selected Product" style={lightboxImageStyle} />
            {lightboxImages.length > 1 && (
              <>
                <div style={leftButtonStyle} onClick={(e) => { e.stopPropagation(); prevImage(); }}>&lt;</div>
                <div style={rightButtonStyle} onClick={(e) => { e.stopPropagation(); nextImage(); }}>&gt;</div>
              </>
            )}
          </div>
        )}

{selectedName === 'Slots' && (
  <div style={imageContainerStyle}>
    {appointments.length > 0 ? (
      appointments.map((appointment, index) => (
        <div key={index} style={{ textAlign: 'center', margin: '10px' }}>
          <img 
            src={appointment.image_url} 
            alt={`Appointment ${index + 1}`} 
            style={imageStyle} 
            onClick={() => handleImageClick(0, index, true)} // Pass true to indicate this is an appointment image
          />
          {renderDots(1, 0)} {/* Add dots for the number of images */}
          <p style={productTextStyle}>{appointment.title}</p>
          <p style={productTextStyle}>Date & Time: {appointment.date}, {appointment.time}</p>
          <p style={productTextStyle}>Location: {appointment.location}</p>
          <p style={productTextStyle}>
            {appointment.slots > 0 ? `Slots Available: ${appointment.slots}` : 'All slots sold out'}
          </p>
          <p style={productTextStyle}>More Details: {appointment.description}</p>
        </div>
      ))
    ) : (
      <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No Slots available, Please Add</p> // Fallback message when no appointments exist
    )}
  </div>
)}


{/* Lightbox for full-screen view */}
{isLightboxOpen && lightboxImages.length > 0 && (
  <div style={lightboxOverlayStyle} onClick={closeLightbox}>
    <img src={lightboxImages[lightboxIndex]} alt="Selected Appointment" style={lightboxImageStyle} />
    {lightboxImages.length > 1 && (
      <>
        <div style={leftButtonStyle} onClick={(e) => { e.stopPropagation(); prevImage(); }}>&lt;</div>
        <div style={rightButtonStyle} onClick={(e) => { e.stopPropagation(); nextImage(); }}>&gt;</div>
      </>
    )}
  </div>
)}
 </div>

      {/* Popup for Adding Product, Appointment, or Post */}
{isAddPopupOpen && (
  <>
    <div style={overlayStyle} onClick={() => setIsAddPopupOpen(false)} />
    <div style={popupStyle}>
      <h6>
        {selectedName === 'Store'
          ? 'Add Product'
          : selectedName === 'Slots'
          ? 'Add Slot'
          : 'Add Post'}
      </h6>
      <button
        style={buttonStyle}
        onClick={() => {
          setIsAddPopupOpen(false);
          if (selectedName === 'Store') {
            navigate('/add-product');
          } else if (selectedName === 'Slots') {
            navigate('/add-appointment');
          } else if (selectedName === 'Posts') {
            navigate('/addpost');
          }
        }}
      >
        Proceed
      </button>
    </div>
  </>
)}


      {/* Popup for editing profile */}
{isEditPopupOpen && (
  <>
    <div style={overlayStyle} onClick={() => setIsEditPopupOpen(false)} />
    <div style={popupStyle}>
      <h6>Edit Profile</h6>
      <input
        style={inputStyle}
        type="text"
        value={editName}
        onChange={handleEditNameChange} // Use lowercase handler for name input
        placeholder="Enter your name"
      />
      <input
        style={inputStyle}
        type="text"
        value={editMobile}
        onChange={handleEditMobileChange} // Use digit-only handler for mobile input
        placeholder="Enter your mobile number"
      />
      <button 
        style={buttonStyle} 
        onClick={handleSave} 
        disabled={!isSaveButtonEnabled} // Enable button only if fields are valid
      >
        Save
      </button>
    </div>
  </>
)}
      <BottomNavbar />
    </div>
  );
};

export default ProfileScreen;